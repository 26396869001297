import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import './shipping.scss';
import Pagination from '@mui/material/Pagination';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WordIcon from 'app/component/word-document-icon';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ShippingUploadIcon from 'app/component/shipping-upload';
import FilesDragAndDrop from '@yelysei/react-files-drag-and-drop';
import FolderOpen from 'app/component/folder-open';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
}

const initialFormData = {
  id: '0',
  type: '',
  priority: '',
  documentSecurity: '',
  startDate: new Date(),
  lastDate: new Date(),
  regarding: '',
  fileContent: '',
  to: [],
  cc: [],
  acknowledge: [],
  approval: [],
};

function DragDrop() {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <div className="row">
      <div className="col-4">
        <a href="/content/images/home/testpdf.pdf" target="_blank">
          <ShippingUploadIcon />
        </a>
      </div>
    </div>
  );
}

export const ShippingUpload = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [showList, setShowList] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState(
    'priority.keyword,asc'
  );
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listOrder, setListOrder] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<Boolean>(false);

  const [formData, setFormData] = useState(initialFormData);
  const [searchByDropDown, setSearchByDropDown] = useState<any>(
    'Search by'
  );
  const [filterAccountType, setfilterAccountType] = useState<any>(
    'Account Type'
  );
  const [filterBankRdn, setfilterBankRdn] = useState<any>('Bank RDN');

  const [modalAdd, setModalAdd] = useState<any>(false);
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const fileTypes = ['csv'];
  const [file, setFile] = useState(null);
  const drop = React.useRef(null);
  const [dragging, setDragging] = useState(true);
  var [contentHTML, setContentHTML] = useState('');

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([]);
      setDefault();
    } else {
      getSearch(props.location.state?.search);
    }
  }, [
    props.location.state?.search,
    sortType,
    sortPriority,
    sortLastModified,
    sortDate,
    page,
    size,
    totalPage,
  ]);

  useEffect(() => {
    console.log('data search', props.location.state?.search);
    // alert(props.location.state.search)
    getSearch(props.location.state?.search);
    getListAnnouncement(page, limit);
    // getIsAdmin();
    // getIsAnnounce();
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc');
    setSortPriority('priority.keyword,asc');
    setSortLastModified('lastApprovedByName.keyword,asc');
    setSortDate('date,asc');
  };

  const getListAnnouncement = (page, limit) => {
    axios
      .get(
        `https://dev-payment-commerce.miraeasset.io/shipping/rates`
      )
      .then((res) => {
        console.log(res);
        setListOrder(res.data.data);
        // setTotalPage(res.data.data?.totalPages);
      });
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const isValidFileUploaded = (file) => {
    console.log('type', file.type);
    if (file.type == '') {
      return true;
    } else {
      console.log('type', file.type);
      console.log('type', file.type.split('/')[1]);
      const validExtensions = ['csv'];
      const fileExtension = file.type.split('/')[1];
      console.log(validExtensions.includes(fileExtension));
      return validExtensions.includes(fileExtension);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 10) {
      return false;
    } else {
      return true;
    }
  }

  const handleFileUpload = (files) => {
    console.log(`cek files ${JSON.stringify(files)}`);
    // console.log(`cek files ${JSON.stringify(files[0])}`);

    const file = files[0];

    Array.from(files).forEach(async (item: any) => {
      if (!isValidFileUploaded(item)) {
        toast.error('Tipe file tidak diperbolehkan', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        //file is valid
        if (!validateSize(item)) {
          toast.error('Ukuran file Tidak Boleh Lebih Dari 10MB', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // let data = {
          //   fileAttachmentId: null,
          //   fileName: item.name,
          //   file: await toBase64(item),
          //   fileContentType: item.type,
          //   fileSize: item.size,
          // };
          const url =
            'https://dev-payment-commerce.miraeasset.io/shipping/upload-rates';
          const formData = new FormData();
          formData.append('myFile', file);
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios
            .post(url, formData, config)
            .then((result) => {
              console.log(`cek result ${result.data}`);
              getListAnnouncement(1, 5);
            })
            .catch((err) => {
              console.log(`cek error ${err}`);
            });
          // axios
          //   .post(
          //     `https://dev-payment-commerce.miraeasset.io/shipping/upload-rates`,
          //     item,
          //     {
          //       headers: {
          //         'Content-Type': 'multipart/form-data',
          //       },
          //     }
          //   )
          // .then((result) => {
          //   console.log(`cek result ${result.data}`);
          //   // setApprovalListOptionNew(dataApproval);
          // })
          // .catch((err) => {
          //   console.log(`cek error ${err}`);
          // });
        }
      }
    });
  };
  // const getIsAdmin = () => {
  //   axios
  //     .get('/services/uaadocservice/api/is-admin-dms')
  //     .then((res) => {
  //       console.log('is Admin', res.data);
  //       setIsAdmin(res.data);
  //     })
  //     .catch((err) => {
  //       setIsAdmin(false);
  //     });
  // };

  // const getIsAnnounce = () => {
  //   axios
  //     .get('/services/uaadocservice/api/is-user-may-announce')
  //     .then((res) => {
  //       console.log('is Admin', res.data);
  //       setIsAnnounce(res.data);
  //     })
  //     .catch((err) => {
  //       setIsAnnounce(false);
  //     });
  // };

  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true);
      axios
        .get(
          `services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`
        )
        .then(async (result) => {
          console.log(result.data);
          setListSearch(result.data.content);
          // setTotalPage(result.data?.totalPages);
          await delayTime(2000);
          setLoadingList(false);
        })
        .catch((err) => {
          setListSearch([]);
          setLoadingList(false);
        });
    }
  };

  // const changeSortType = () => {
  //   if(sortType == 'typeName.keyword,asc') {
  //     setSortType('typeName.keyword,desc')
  //   } else {
  //     setSortType('typeName.keyword,asc')
  //   }
  // }

  // const changeSortPriority = () => {
  //   if(sortPriority == 'priority.keyword,asc') {
  //     setSortPriority('priority.keyword,desc')
  //   } else {
  //     setSortPriority('priority.keyword,asc')
  //   }
  // }

  // const changeSortLastModified = () => {
  //   if(sortLastModified == 'lastApprovedByName.keyword,asc') {
  //     setSortLastModified('lastApprovedByName.keyword,desc')
  //   } else {
  //     setSortLastModified('lastApprovedByName.keyword,asc')
  //   }
  // }

  // const changeSortDate = () => {
  //   if(sortDate == 'date,asc') {
  //     setSortDate('date,desc')
  //   } else {
  //     setSortDate('date,asc')
  //   }
  // }

  // const goToPage = (id) => {
  //   setSearch('')
  //   history.push(`/preview/only/pdf/${id}`)
  // }

  const changePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log(value);
    setPage(value);
    // getListDocumentHistori(value, limit)
    getListAnnouncement(value, limit);
  };

  const changeShow = (e) => {
    // alert(e.target.value)
    let show = e.target.value;
    console.log(limit);
    setPage(1);
    setLimit(show);
    getListAnnouncement(1, show);
    // getListDocumentHistori(1, show)
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // const openModalDelete = (item) => {
  //   setShowModal(true)
  //   setItemSelected(item)
  // }

  const deleteFile = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`services/documentservice/api/announcement/${id}`)
      .then((res) => {
        setLoadingDelete(false);
        toast.success('Success Delete Shipping Upload', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
        setItemSelected(null);
        setPage(1);
        setLimit(5);
        getListAnnouncement(1, 5);
      })
      .catch((err) => {
        setLoadingDelete(false);
        toast.error('Failed Delete Announcement', {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
      });
  };

  const deleteDocxFile = (e) => {
    setDragging(true);
    console.log('delete uploaded docx');
    setContentHTML('');
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const onUpload = (files) => {
    console.log(files);
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    // e.preventDefault();
    // e.stopPropagation();
    // setDragActive(false);
    // if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    //   console.log(
    //     `cek file output ${JSON.stringify(e.dataTransfer.files)}`
    //   );
    // }
    setDragging(false);

    const files = e.dataTransfer.files;
    onUpload(files);
  };

  // triggers when file is selected with click
  const handleChange = function (file) {
    setFile(file);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const goToDetail = (data) => {
    // axios.post(`services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`).then((res) => {
    //   console.log('res')
    //   history.push(`/verification/view/${data.id}`)
    // }).catch((err) => {
    //   history.push(`/verification/view/${data.id}`)
    // })
  };

  // const goToCreate = () => {
  //   history.push('/announcement/create')
  // }

  // const goToView = (id) => {
  //   history.push(`/announcement/edit/${id}`)
  // }

  // const handleSearchBy=(e)=>{
  //   console.log('SELECTED', e);
  //   setSearchByDropDown(e);
  // }

  // const handleFilterAccountType= (e)=>{
  //   console.log('SELECTED', e);
  //   setfilterAccountType(e);
  // }

  // const handleFilterBankRdn= (e)=>{
  //   console.log('SELECTED', e);
  //   setfilterBankRdn(e);
  // }

  return (
    <div
      className="px-5 pt-0"
      style={{ height: '100%', background: '#fff', marginTop: 50 }}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Shipping Upload</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">
              Upload the shipping confirmation file
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div
            id="FilesDragAndDrop"
            ref={drop}
            className="FilesDragAndDrop"
          >
            {dragging ? (
              <FilesDragAndDrop
                // onUpload={(files) => console.log(files)}
                onUpload={(files) => handleFileUpload(files)}
                // onUpload={onUploadTrue}
                // onDrop={handleDrop}
                count={1}
                formats={['csv']}
                successText={'Upload Success'}
                successTime={1000}
                errorTime={2000}
                containerStyles={{
                  width: '70%',
                  height: '50%',
                  borderRadius: 12,
                  border: '1px dashed #F37F26',
                  // backgroundSize: 'contain',
                  // backgroundRepeat: 'no-repeat',
                  paddingTop: '8px',
                  // paddingBottom: '16px',
                  // paddingLeft: '16px',
                  // paddingRight: '16px',
                }}
                openDialogOnClick
              >
                <div className="row d-flex align-items-center justify-content-center p-0">
                  <div className="row d-flex align-items-center justify-content-center p-0">
                    <FolderOpen />
                  </div>
                  <div
                    className="row d-flex align-items-center justify-content-center shadow-sm py-3 mb-2 bg-white rounded"
                    style={{
                      width: 219,
                    }}
                  >
                    <div className="d-flex alilgn-items-center justify-content-center p-0">
                      Drag file disini
                    </div>
                    <div className="d-flex alilgn-items-center justify-content-center p-0">
                      atau
                    </div>
                    <div className="d-flex alilgn-items-center justify-content-center p-0">
                      <span style={{ color: '#F37F26' }}>
                        Klik untuk Upload Shipping
                      </span>
                    </div>
                  </div>
                </div>
              </FilesDragAndDrop>
            ) : (
              <div>
                <FilesDragAndDrop
                  // onUpload={(files) => console.log(files)}
                  onUpload={(files) => handleFileUpload(files)}
                  // onUpload={onUploadTrue}
                  // onDrop={handleDrop}
                  count={1}
                  formats={['docx']}
                  successText={'Upload Success'}
                  successTime={1000}
                  errorTime={2000}
                  containerStyles={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 12,
                    border: '1px dashed #cccccc',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#FBDEC9',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  }}
                  openDialogOnClick
                >
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <WordIcon />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <div></div> */}
                      <p>
                        File <span id="name"></span> Uploaded.
                      </p>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </FilesDragAndDrop>
                <div
                  style={{
                    paddingTop: '16px',
                    paddingLeft: '16px',
                  }}
                  className="float-right"
                >
                  <Button
                    color="primary"
                    onClick={(files) => deleteDocxFile(files)}
                  >
                    Remove File{' '}
                    <FontAwesomeIcon icon="trash" color="red" />
                  </Button>
                </div>
              </div>
            )}
          </div>
          {/* <FilesDragAndDrop onUpload={onUpload} /> */}
          {/* <FileUploader
            multiple={true}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <p>
            {file
              ? `File name: ${file[0].name}`
              : 'no files uploaded yet'}
          </p> */}
          {/* <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? 'drag-active' : ''}
            >
              <div>
                <p>Drag and drop your file here or</p>
                <button
                  className="upload-button"
                  onClick={onButtonClick}
                >
                  Upload a file
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form> */}
          {/* <a href="/content/images/home/testpdf.pdf" target="_blank">
            <ShippingUploadIcon />
          </a> */}
        </div>
      </div>
      <div className="my-3 bg-light">
        <table className="table table-striped table-centered table-nowrap mb-0 table-responsive">
          <thead>
            <tr>
              <th className="py-4 px-4">No</th>
              <th className="py-4 px-4">Origin</th>
              <th className="px-4">Destination</th>
              <th className="px-4">Expedition</th>
              <th className="px-4">Type</th>
              <th className="px-4">Estimate</th>
              <th
                className="px-4 text-center"
                style={{ width: '4%' }}
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {listOrder.map((item, index) => {
              return (
                <tr>
                  <td className="" onClick={() => goToDetail(item)}>
                    {index + 1}
                  </td>
                  <td
                    className=""
                    style={{
                      wordBreak: 'break-all',
                      minWidth: '100px',
                      maxWidth: '150px',
                      whiteSpace: 'pre-wrap',
                    }}
                    onClick={() => goToDetail(item)}
                  >
                    {item.origin}
                  </td>
                  <td
                    className=""
                    style={{
                      wordBreak: 'break-all',
                      minWidth: '200px',
                      maxWidth: '2500px',
                      whiteSpace: 'pre-wrap',
                    }}
                    onClick={() => goToDetail(item)}
                  >
                    {item.destination}
                  </td>
                  <td className="" onClick={() => goToDetail(item)}>
                    {item?.expedition_code}
                  </td>
                  <td
                    className=""
                    style={{
                      wordBreak: 'break-all',
                      minWidth: '100px',
                      maxWidth: '250px',
                      whiteSpace: 'pre-wrap',
                    }}
                    onClick={() => goToDetail(item)}
                  >
                    {item.type}
                  </td>
                  <td className="" onClick={() => goToDetail(item)}>
                    {item.estimate_day}
                  </td>
                  <td className="">
                    {item.price}
                    {/* <a
                      className="btn btn-primary mx-2"
                      href="/content/images/home/testpdf.pdf"
                      target="_blank"
                    >
                      {' '}
                      Download
                    </a>
                    <a
                      className="btn btn-danger mx-2"
                      href="/content/images/home/testpdf.pdf"
                      target="_blank"
                    >
                      {' '}
                      Export
                    </a> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6">
          <div className="form-group row">
            <label
              htmlFor="priority"
              className="col-sm-1 col-form-label"
            >
              Show
            </label>

            <div className="" style={{ width: '80px' }}>
              <select
                name="show"
                value={limit}
                className="custom-select"
                onChange={(e) => changeShow(e)}
              >
                <option value="2">2</option>
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <label
              htmlFor="priority"
              className="col-sm-2 col-form-label"
            >
              Entries
            </label>
          </div>
        </div>
        <div
          className="col-12 col-md-6 col-lg-6"
          style={{ justifyContent: 'end', display: 'flex' }}
        >
          <Pagination
            count={5}
            color="primary"
            page={page}
            showFirstButton
            showLastButton
            onChange={changePage}
          />
        </div>
        <div className="d-flex justify-content-end col-12 mt-4">
          <CSVLink
            filename={'ShippingUpload.csv'}
            data={listOrder}
            className="btn btn-primary"
          >
            Download Report
          </CSVLink>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModal(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to delete{' '}
            <span className="font-weight-bold">
              "{selectedItem?.title}"
            </span>{' '}
            announcement ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => deleteFile(selectedItem?.id)}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModal(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
// type StateProps = ReturnType<typeof mapStateToProps>
// type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingUpload);
